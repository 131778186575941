@tailwind base
@tailwind components
@tailwind utilities

@import style/animations
@import intl-tel-input/build/css/intlTelInput.css
@import '@ng-select/ng-select/themes/default.theme.css'

*
  box-sizing: border-box

html, body
  margin: 0
  width: 100%
  height: 100%

  // Height / width
  --menu-height-header: 50px
  --menu-height-footer: 60px
  --radius-unit-width: 130px
  --separation-date-range-width: 34px
  --page-height: calc(100% - 80px)

a:link, a:visited
  text-decoration: none

.form
  &-primary
    margin: auto
    display: block
    width: auto
    padding: 5px 10px 15px 10px
    background-color: var(--grey-light)
    border-radius: 11px
    margin-top: 20px
    margin-bottom: 20px

.notifications
  text-align: center
  padding: 0px 0px 10px 0px
  .mat-icon
    height: 40px
    width: 40px

.iti__flag
  background-image: url("/assets/images/flags.png")

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
  .iti__flag
    background-image: url("/assets/images/flags@2x.png")

[tabindex='-1']:focus
  outline: none

.highlighted
  font-weight: 500
  border-radius: 3px

.visually-hidden
  clip: rect(0 0 0 0)
  clip-path: inset(50%)
  height: 1px
  overflow: hidden
  position: absolute
  white-space: nowrap
  width: 1px
  padding: 0
  margin: -1px
  border-width: 0

textarea.mat-input-element
  padding: 8px 10px !important

.loading-screen
  flex-flow: column wrap
  justify-content: center
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 201
  display: flex

.ao-loading-spinner
  font-size: 2.6em
  animation: rotate 1.5s linear infinite

@keyframes rotate
  100%
    transform: rotate(1turn)

.required-error
  border-color: var(--red-error) !important
  &-auto
    border: 1px solid var(--red-error) !important

.pass-required
  border-color: var(--green-pass) !important
  &-auto
    border: 1px solid var(--green-pass) !important

app-year-picker
  p-calendar
    .p-inputtext, .p-button.p-button-icon-only
      padding: 10px 0.75rem

.datepicker-container
  p-calendar
    span
      height: 40px

p-calendar
  border-width: 1px

mat-label:has(+ app-year-picker)
  padding: 5px 0 4px 0


/*********************************************************/
/** Form classes                                        **/
/*********************************************************/

textarea.mat-input-element
  border: 1px solid var(--grey)

/*********************************************************/
/** Text classes                                        **/
/*********************************************************/

.t-a-c
  text-align: center

.t-d-lt
  text-decoration: line-through

/*********************************************************/
/** Position classes                                    **/
/*********************************************************/

.pos-r
  position: relative

/*********************************************************/
/**  Font classes                                       **/
/*********************************************************/

.bold
  font-weight: bold

.text-s
  font-size: 14px


@media screen and (max-width: 680px)
  .form-container
    flex-wrap: wrap
    div.form-content
      width: 100%
      flex-basis: unset
      margin-left: unset
      margin-right: unset

/*********************************************************/
/** Color classes                                      **/
/*********************************************************/
.color-red
  color: var(--red-cross)

.color-black
  color: var(--black)

.color-grey-light-icon
  color: var(--grey-light-icon)

/*********************************************************/
/** Utility classes                                     **/
/*********************************************************/

*
  box-sizing: unset

.disp-n
  display: none

.full-width
  width: 100%

.half-width
  width: 50%

.standard-icon-size
  height: 40px !important
  width: 40px !important

app-create-notification
  display: inline-block
  margin: 10px 5px 20px 5px
  padding: 9px 10px
  height: 40px
  width: 40px
  &.no-padding
    padding: 0
  &.no-margin
    margin: 0

@layer utilities
  .text-indent-2
    text-indent: 0.5rem
  .text-indent-4
    text-indent: 1rem
  .text-indent-6
    text-indent: 1.5rem